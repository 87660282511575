@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: white;
  font-family: "Poppins", sans-serif;
}
html {
  background: linear-gradient(180deg, #000 0%, #500188 100%);
  min-height: 100%;
}
.bg-btn-gradient {
  background: linear-gradient(135deg, #ee9ae5 0%, #5961f9 100%);
}

.marquee-gradient {
  background: linear-gradient(
    135deg,
    rgba(238, 154, 229, 0.2) 0%,
    rgba(89, 97, 249, 0.2) 100%
  );
}
.text-color {
  background: linear-gradient(135deg, #ee9ae5 0%, #5961f9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.path-color {
  color: linear-gradient(135deg, #ee9ae5 0%, #5961f9 100%);
}

.btn-gradient {
  background: linear-gradient(135deg, #ee9ae5 0%, #5961f9 100%);
}

.btn-dashboard {
  background-color: rgba(255, 255, 255, 0.3);
}
.radial-gradient {
  background: radial-gradient(
    70.71% 70.71% at 50% 50%,
    #49415b 0%,
    #412f58 12%,
    #3f1866 26%,
    #66388a 100%
  );
}

.slick-slide div {
  margin: 0 0.5rem;
}

.slick-next {
  right: -30px;
}

.slick-dots li.slick-active button::before {
  color: white;
}

.boxshadow {
  box-shadow: -13px 11px 9px 0px rgba(15, 15, 20, 0.29) inset;
}

.myControlClassName {
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dropdown {
  margin: 0 auto;
  z-index: 10000;
  position: relative;
  width: 50%;
  text-align: center;
  height: auto;
}
.dropdown,
.dropdown * {
  z-index: 10;
}
.dropdown .dropdown-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: transparent;
  border: 2px solid #ce9ffc;
  color: #ce9ffc;
  font-weight: 600;
  font-size: 20px;
}
.dropdown-content {
  background-color: #3f1866;
  position: absolute;
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px 5px;
  animation: rotateMenu 400ms ease-in-out forwards;
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}
.dropdown-content .item:hover {
  background: grey;
}

.rsu-go-up {
  /* Add background color */
  background-color: lightblue;

  /* Add border */
  border: 1px solid red;

  /* Add border-radius */
  border-radius: 10px;

  /* Add padding for better appearance */
  padding: 8px;
}

/* Optionally, you can center the content inside the button */
.rsu-go-up div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
